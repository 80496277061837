/* eslint-disable max-depth */
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Constants
import { IS_MOBILE } from '../Constants';
// Services
import { InitServ, CookieServ, ApiServ, StyleServ } from './index';
// Theme section components
import { BannerAreaComponent, DiscountBarComponent, GridContentComponent, AboutUsComponent, ReferralComponent, CallToActionComponent, TextContentComponent, ServicesComponent, FeaturesComponent, TeamMembersComponent, OpeningHoursComponent, FAQComponent, ClientReviewsComponent, ContactUsComponent, BenefitsComponent, JoinOurTeamComponent, LocationsComponent, PartnersComponent, AppWidgetsComponent, HtmlCodeComponent, ReviewsComponent, RowComponent, EmbedFormComponent, GridServicesComponent, EmbedVideoComponent, GalleryComponent, ServiceGridComponent, ThankYouComponent, BlogListingComponent, BlogGridSectionComponent, EmbedHiringFormComponent, EmbedLeadFormComponent, EmbedCampaignFormComponent } from '../ThemeElements';
// Elements components
import { TextComponent, ImageComponent, BulletListComponent, ButtonComponent } from '../ThemeElements/Elements';
// Session components
import { LoginComponent, SignupComponent, ForgotPasswordComponent, ResetPasswordComponent, ReferralSignupComponent, ReAuthPaymentComponent, InvPaymentComponent, InvoiceComponent, AddPaymentComponent } from '../Session';
// Gift cards components
import { GiftCardListingComponent, SendGiftCardComponent } from '../GiftCards';
// Referrals
import { ReferralBannerComponent, ReferralListingComponent } from '../Referrals';
// Lead form
import { LeadFormComponent } from '../LeadForm/LeadForm/LeadForm.component';
// Add booking components
import { AddBookingFormComponent } from '../AddBookings';
// Booking short forms
import { BkngShortFormComponent } from '../ShortForm';
// Theme popups
import { WelcomePopupComponent, DiscountPopupComponent, SubscribePopupComponent, ContactUsPopupComponent, TextPopupComponent, VideoPopupComponent, ApplyNowPopupComponent, ShortFormPopupComponent, ThemePopupsComponent } from '../ThemeElements/Popups';
import { DashboardComponent } from '../Dashboard/Dashboard.component';
import { AddOtherContactPopupComponent, AddressCardPopupComponent, DriveComponent, EditProfileComponent, InfoComponent, NotificationsComponent } from '../UserPages';
import { ChangePasswordPopupComponent, AddCardPopupComponent } from '../Global/UserComponents/Profile';
import { DeactivateAccountPopupComponent } from '../Core/Layout';
import { ConfirmPopupComponent, RatingPopupComponent } from '../Popups';
import { ContractPopupComponent } from '../Session/Invoices/ContractPopup/ContractPopup.component';

// Component class on the basis of slug
const COMPONENTS : any = {
	banner: BannerAreaComponent,
	referral: ReferralComponent,
	how_it_works: GridContentComponent,
	about_us: AboutUsComponent,
	call_to_action: CallToActionComponent,
	text_content: TextContentComponent,
	services: ServicesComponent,
	features: FeaturesComponent,
	team_members: TeamMembersComponent,
	opening_hours: OpeningHoursComponent,
	faq: FAQComponent,
	testimonial: ClientReviewsComponent,
	row: RowComponent,
	contact_us: ContactUsComponent,
	our_clients: PartnersComponent,
	location_map: LocationsComponent,
	html_code: HtmlCodeComponent,
	live_reviews: ReviewsComponent,
	app_widgets: AppWidgetsComponent,
	benefits: BenefitsComponent,
	join_our_team: JoinOurTeamComponent,
	text: TextComponent,
	image: ImageComponent,
	bullet_list: BulletListComponent,
	button: ButtonComponent,
	blog: BlogListingComponent,
	blog_section: BlogGridSectionComponent,
	thank_you: ThankYouComponent,
	embed_booking_form: EmbedFormComponent,
	embed_login_form: EmbedFormComponent,
	embed_signup_form: EmbedFormComponent,
	embed_send_gift_card_form: EmbedFormComponent,
	embed_forgot_password_form: EmbedFormComponent,
	embed_quote_form: EmbedFormComponent,
	hiring_form:EmbedHiringFormComponent,
	grid_services: GridServicesComponent,
	embed_video: EmbedVideoComponent,
	gallery: GalleryComponent,
	service_grid: ServiceGridComponent,
	discount_bar: DiscountBarComponent,
	login_form: LoginComponent,
	signup_form: SignupComponent,
	forgot_form: ForgotPasswordComponent,
	reset_password: ResetPasswordComponent,
	referral_signup_form: ReferralSignupComponent,
	giftcard_form: SendGiftCardComponent,
	referral_banner: ReferralBannerComponent,
	referral_list: ReferralListingComponent,
	lead_form: LeadFormComponent,
	form: AddBookingFormComponent,
	short_form: BkngShortFormComponent,
	welcome_popup: WelcomePopupComponent,
	discount_popup: DiscountPopupComponent,
	subscribe_popup: SubscribePopupComponent,
	contact_us_popup: ContactUsPopupComponent,
	text_popup: TextPopupComponent,
	video_popup: VideoPopupComponent,
	apply_now_popup: ApplyNowPopupComponent,
	short_form_popup: ShortFormPopupComponent,
	dashboard: DashboardComponent,
	info:InfoComponent,
	notifications: NotificationsComponent,
	drive: DriveComponent,
	edit_profile: EditProfileComponent,
	gift_cards:GiftCardListingComponent,
	change_pwd_popup:ChangePasswordPopupComponent,
	deactivate_account_popup: DeactivateAccountPopupComponent,
	confirm_popup: ConfirmPopupComponent,
	add_card_popup: AddCardPopupComponent,
	address_card: AddressCardPopupComponent,
	rating_popup:RatingPopupComponent,
	add_contact:AddOtherContactPopupComponent,
	reauth_card: ReAuthPaymentComponent,
	invoice: InvoiceComponent,
	invoice_payment: InvPaymentComponent,
	contract_popup: ContractPopupComponent,
	embed_lead_form: EmbedLeadFormComponent,
	embed_campaign_form: EmbedCampaignFormComponent,
	add_payment: AddPaymentComponent
}
@Injectable({
	providedIn: 'root'
})
export class RenderComponentServ {
	// Variables
	componentRef: any;
	private _pageData: any;
	private _popupData: any = {};
	private _pageApiHitCount: number = 0;
	statusType: string = 'status';
	private admnStngs: any;
	private destroy = new Subject<void>();

	// Getter function
	get pageData(): any { return this._pageData; }
	get popupData(): any { return this._popupData; }
	get pageApiHitCount(): any { return this._pageApiHitCount; }

	constructor(@Inject(DOCUMENT) private doc: any, private initServ: InitServ, private cookieServ: CookieServ, private dialog: MatDialog, private apiServ : ApiServ, private styleServ: StyleServ) {
		this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
		// Set the element status type: mobile_status/status
		this.statusType = IS_MOBILE ? 'mobile_status' : 'status';
	}

	/**
	 * Set page data
	 */
	set setPageData(data: any) {
		this._pageData = data;
	}
	/**
	 * Set the popup data based on popup id
	 * @param popupId: popup id
	 * @param data: popup data
	 */
	public setPopupData(popupId: any, data: any){
		this._popupData[popupId] = data;
	}

	/**
	 * Set page api hit count
	 */
	set setPageApiHitCount(count: number) {
		this._pageApiHitCount = count;
	}

	/**
	 * Render component according to slug
	 * @param secId: section id
	 * @param secSlug: section slug
	 * @param vcRef: ViewContainerRef
	 * @param popupId: popup id
	 */
	public renderComponent(secId: string, secSlug: string, vcRef: any, popupId: any = null, dialogRef: any = null): void {
		if(COMPONENTS[secSlug]){
			this.componentRef = vcRef.createComponent(COMPONENTS[secSlug]);
			this.componentRef.instance.secId = secId;
			if(popupId){
				this.componentRef.instance.popupId = popupId;
				if(dialogRef){
					this.componentRef.instance.dialogRef = dialogRef;
				}
			}
		}
	}
	/**
	 * Set the page title
	 * @param sett page settings
	 */
	public setPageTitle(sett: any= null): void {
		if(sett && sett.seo_title && sett.seo_title != ''){
			this.doc.title = sett.seo_title;
		} else{
			if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.store && this.admnStngs?.merchant_settings?.store?.store_name){
				this.doc.title = this.admnStngs?.merchant_settings?.store?.store_name;
			}
		}
	}
	/**
	 * Add the page tracking codes.
	 * @param sett page settings
	 */
	// eslint-disable-next-line complexity
	public addTrackingCodes(sett: any): void {
		if(sett?.event_tracking_code != '' || sett?.ga_tracking_code != '' || sett?.gad_tracking_code != '' || sett?.fb_tracking_code != ''){
			let trackingCodes = '<div id="bk-tracking-codes-wrap">';
			if(sett?.event_tracking_code != ''){
				trackingCodes += sett.event_tracking_code;
			}
			if(sett?.ga_tracking_code != ''){
				trackingCodes += sett.ga_tracking_code;
			}
			if(sett?.gad_tracking_code != ''){
				trackingCodes += sett.gad_tracking_code;
			}
			if(sett?.fb_tracking_code != ''){
				trackingCodes += sett.fb_tracking_code;
			}
			trackingCodes += '</div>'
			let trackEl = document.getElementById("bk-tracking-codes-wrap");
			if(trackEl){
				trackEl.remove();
			}
			const range = document.createRange();
			range.selectNode(document.getElementsByTagName("BODY")[0]);
			const documentFragment = range.createContextualFragment(trackingCodes);
			document.body.appendChild(documentFragment);
		}
	}
	/**
	 * Load theme popups
	 */
	popupTimer: any = {};
	// eslint-disable-next-line complexity
	public loadThemePopups(pageSett: any = null): void {
		// Clear the popup timer based on popup id
		if(this.popupTimer && (Object.keys(this.popupTimer)).length > 0){
			for(let timer in this.popupTimer){
				clearTimeout(this.popupTimer[timer]);
			}
		}
		this._popupData = {};
		if(this.initServ.apiLoadStatus.themePopups){
			let popups: any = this.initServ.themePopupsData;
			if(popups){
				if(pageSett.popup_ids && (pageSett.popup_ids).length > 0){
					for(let popupId of pageSett.popup_ids){
						if(popups[popupId] && popups[popupId]?.settings){
							this.deletePopupCookie(popups[popupId]);
							let sett = popups[popupId]?.settings;
							if(sett.autoload_status && !this.cookieServ.getPopupCookie(popupId)){
								let delay: any = sett?.delayed_by+'000';
								this.popupTimer[popupId] = setTimeout(()=>{
									this.checkPopupType(popups[popupId]);
									const popupEle: any = document.querySelector('[data-popupId="'+popupId+'"]');
									if(!popupEle){
										this.buildPopup(popups[popupId]._id);
									}
								}, +delay);
							}
						}
					}
				}
			}
		}else{
			this.initServ.loadThemePopups().then(() => {
				this.loadThemePopups(pageSett);
			});
		}
	}
	/**
	 * Build popup
	 */
	public buildPopup(popupId: any, disableClose: boolean = false):any {
		// Query params
		let queryParams: any = {language:this.initServ.savedLng,theme_slug: this.initServ.theme, mode: 'live'};
		if(this.initServ.theme){ queryParams['mode'] = 'preview'; }
		this.apiServ.callApiWithPathQueryVars('GET', 'SinglePopup',[popupId], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, popupId, disableClose));
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private onResultCallback(res:any, popupId: any, disableClose: boolean = false){
		if(this.apiServ.checkAPIRes(res) && res.data && (Object.keys(res.data)).length > 0){
			this.themePopups(res.data, popupId, disableClose);
			this.styleServ.applyPageStyle(res,'popup');
		}
	}
	/**
	 * Theme popups
	 * @param popupId: popup id
	 * @returns any
	 */
	public themePopups(popupData: any, popupId: any, disableClose: boolean = false): any {
		let ref: MatDialogRef<ThemePopupsComponent>
		if(disableClose){
			ref = this.dialog.open(ThemePopupsComponent, {disableClose: true});
		}else{
			ref = this.dialog.open(ThemePopupsComponent);
		}
		ref.componentInstance.popupData = popupData;
		ref.componentInstance.popupId = popupId;
		ref.componentInstance.disableClose = disableClose;
		return ref.afterClosed();
	}
	/**
	 * Check the popup type, based this create a cookie
	 * @param popup: popup data
	 */
	private checkPopupType(popup: any): void {
		switch (popup.settings.when_popup_show) {
			case "every_visit":
				if(popup.settings.when_should_popup_show == 'once'){
					this.cookieServ.createPopupCookie(popup?._id);
				}
				break;
			case "once_every":
				this.cookieServ.createPopupCookie(popup?._id, popup.settings.once_every);
				break;
			case "once_every_x":
				this.cookieServ.createPopupCookie(popup?._id, popup.settings.once_every_x, +popup.settings.once_every_x_val);
				break;
		}
	}
	/**
	 * Delete cookie
	 * @param popup: popup data
	 */
	private deletePopupCookie(popup: any): void {
		switch (popup.settings.when_popup_show) {
			case "every_visit":
				if(popup.settings.when_should_popup_show == 'each_page_load'){
					let name: string = 'popup-'+popup?._id;
					this.cookieServ.deleteCookie(name);
				}
				break;
		}
	}
}
