

let baseUrl = window.location.protocol+'//'+window.location.host;
let domainName = ((window.location.hostname).split("."))[0];
let devHost = "127.0.0.1:8080";
// Bucket cdn
let fileCdn = 'https://bkstgfiles.s3.us-west-2.amazonaws.com';
// Cloud front cdn
let bkcdn = 'https://d1k1uzhv0gy2ld.cloudfront.net';
// var bkcdn = 'https://cdn.bookingkoala.co.in';
if(window.location.host === devHost){
	domainName = 'restructuretesting';
	baseUrl = 'https://'+domainName+'.bookingkoala.co.in';
}
export const environment: any = {
	production: true,
	hmr: true,
	devHost: devHost,
	appVersion: 'customer-dev-1.0.1',
	baseUrl: baseUrl,
	ipInfoUrl: baseUrl+'/ipinfo/',
	apiUrl: baseUrl+'/api/v1/',
	apiV3: baseUrl+'/api/v3/',
	apiV5: baseUrl+'/api/v5/',
	themeUrl: baseUrl+'/bktheme/v1/',
	themeUrlV3: baseUrl+'/bktheme/v3/',
	campaignUrl: baseUrl+'/campaigns/v1/',
	checklistUrl : baseUrl + '/checklist/v1/',
	campaignV3: `${baseUrl}/campaigns/v3/`,
	invoiceUrl: baseUrl + '/bkinvoice/v1/',
	leadsUrl: baseUrl + '/leads/v1/',
	transUrl: baseUrl+'/translation/v1/',
	imgBase: bkcdn,
	assetsBase: bkcdn,
	domainName: domainName,
	tinymceAPIKey: 'h8gfqx3yk5exkxlgqe9ziw9jzxtvt0zxpdomaywe6s3ndc4h',
	fileCdn: fileCdn,
	bkcdn: bkcdn,
	stripe: 'https://js.stripe.com/v3/',
	// paypal: 'https://js.braintreegateway.com/web/3.47.0/js/client.min.js',
	paypal:'https://js.braintreegateway.com/web/3.91.0/js/client.min.js',
	paypalThreeDSecure: 'https://js.braintreegateway.com/web/3.91.0/js/three-d-secure.min.js',
	paypalHostedFields: 'https://js.braintreegateway.com/web/3.91.0/js/hosted-fields.min.js',
	paypalDeviceData: 'https://js.braintreegateway.com/web/3.91.0/js/data-collector.min.js',
	square: 'https://sandbox.web.squarecdn.com/v1/square.js',
	authorizedotnet: 'https://jstest.authorize.net/v1/Accept.js',
	// bugsnagKey: 'b8375536a7674907fb34f70967b69de4', // staging
	bugsnagKey: '8e27a2a9230b2c71bfa72a8b30b4fd6c', // Live
	// googleApi:'https://maps.googleapis.com/maps/api/js?key=AIzaSyB_9rAsvrXGEuc00IcnJ-14VLfqAgyh1tkfjkhfks&libraries=places',
	// For test with real data staging live key
	googleApi:'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9tjqloFYhv498Dwx2q8qd-WcHoa6o94g&libraries=places',
	googlePlatform: 'https://apis.google.com/js/platform.js',
	logRocketKey: '02cvdq/bk',
	themeVersion:'71',
	ejabSocketURL : "wss://sonnymaintenances.com:5280/xmpp",
	ejabDomain : "sonnymaintenances.com",
	privateCdn:bkcdn,

	// encryptionKey: 'a1eec7e79f942e13680a1de1c338ec9e',
	// We are spliting the encryptionKey in 4 equal parts and concatenate them before use.
	// Use it as: env.arjun+env.bhishma+env.chanakya+env.drona
	arjun: 'a1eec7e7',
	bhishma: '9f942e13',
	chanakya: '680a1de1',
	drona: 'c338ec9e',

	// encryptionIv: '79d58e246365143b',
	// We are spliting the encryptionIv in 2 equal parts and concatenate them before use.
	// Use it as: env.astonmartin+env.bentley
	astonmartin: '79d58e24',
	bentley: '6365143b',

	// New method staging keys
	// We are spliting the keys in equal parts and concatenate them before use.
	// Use it as: env.abhimanyu+env.bharata+env.chandra+env.devika, env.audi+env.buick+env.chevrolet+env.dodge, env.satya+env.treta+env.dvaeara+env.kali

	// encryption key(Header): '1GG5whsCV6TufNFPubePavQNc6Q8Ewct',
	abhimanyu: '1GG5whsC',
	bharata: 'V6TufNFP',
	chandra: 'ubePavQN',
	devika: 'c6Q8Ewct',

	// HMAC-256 hash key(Header): b613679a0814d9ec772f95d778c35fc5ff1697c493715653c6c712144292c5ad
	audi: 'b613679a0814d9ec',
	buick: '772f95d778c35fc5',
	chevrolet: 'ff1697c493715653',
	dodge: 'c6c712144292c5ad',

	// Third party keys(payment keys) and others: 6SkguZYveyTlbs2AkLU1GwfAKrnZYB9m
	satya: '6SkguZYv',
	treta: 'eyTlbs2A',
	dvaeara: 'kLU1GwfA',
	kali: 'KrnZYB9m'
};
